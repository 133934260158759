// Don't bother putting new departments in alpahbetical order, the component that uses this array sorts them anyway.
export const departments = [
  "Academic Accounting",
  "Academic Support Office",
  "Access and Participation",
  "Access & Participation Fund",
  "Addiction Research Group",
  "Admin and Projects",
  "Administration & Support",
  "Amaethon Ltd",
  "Archaeology",
  "Archaeology ADS",
  "Architecture and the Built Environment",
  "Arts & Humanities Collaborative Centres",
  "Associate Body - York Science Park Ltd",
  "Assuring Autonomy InternationalProgramme",
  "BA Festival of Science",
  "Berriland Ltd",
  "Biochemistry",
  "BioEconomy Planning Unit",
  "Bioincubator Ltd",
  "Bioincubator York Ltd",
  "Biology",
  "Biology academic allocations",
  "Biology Academics' Reserves",
  "Biology Departmental Support",
  "Biology HoD Support",
  "Biology Infrastructure & Facilities",
  "Biology Miscellaneous",
  "Biology Studentships",
  "Biology Teaching",
  "Biology Teaching Administration",
  "Biomedical Sciences",
  "Biorenewables Development Centre Ltd",
  "Biorenewables Development Centre (UoY)",
  "Building Research & Innovation Capacity",
  "Campus Services - Facilities",
  "Cancer Research Unit",
  "Cardiovascular Health",
  "CARMEN",
  "Centre for Applied Human Rights",
  "Centre for Child Development &Well-Being",
  "Centre for Christianity and Culture",
  "Centre for Defence Economics",
  "Centre for Early Modern Studies",
  "Centre for Eighteenth Century Studies",
  "Centre for Experimental Economics",
  "Centre for HE Academy Psychology",
  "Centre for Health Economics",
  "Centre for Health+Public Sector Mgmt",
  "Centre for Housing Policy",
  "Centre for Hyperpolarisation in MRI",
  "Centre for Immunology and Infection",
  "Centre for Leadership and Management",
  "Centre for Lifelong Learning",
  "Centre for Linguistic History & Diversit",
  "Centre for Magnetic Resonance",
  "Centre for Medieval Studies",
  "Centre for Modern Studies",
  "Centre for Public Understanding of Past",
  "Centre for Reviews and Dissemination",
  "Centre for Women's Studies",
  "Centre Renaissance & Early Mod Studies",
  "Chemistry",
  "Chief Operating Officer's Office",
  "CITY College, Europe Campus",
  "CNAP",
  "Commercialisation",
  "Commercial Services",
  "Communications",
  "Computer Science",
  "Continuing Professional Development",
  "Count On",
  "Creative Technology Centre",
  "Cryobiology",
  "Digital Heritage CIRC",
  "Dyslexia Action",
  "Economic Development",
  "Economics",
  "Education",
  "Electronic Engineering",
  "Energy Co Central",
  "Energy Co External Trade",
  "Energy Co Group Trade",
  "English and Related Literature",
  "Environmental Archaeology Unit",
  "Environmental Sustainability Academy",
  "Environment and Geography",
  "Epidemiology and Cancer Statistics Group",
  "Epidemiology & Genetics Unit",
  "Equality, Diversity and Inclusion",
  "Equal Opportunities Finance code",
  "Estates Development",
  "Estates Director",
  "Estates Operations",
  "Executive Operations",
  "Experimental Medicine and Biomedicine",
  "External Relations",
  "Faculty of Arts and Humanities",
  "Faculty of Sciences",
  "Faculty of Social Sciences",
  "Finance",
  "Finance Business Systems",
  "Finance - non-BAU",
  "Financial Information and Reporting",
  "FM Management Accounts",
  "Food & Environment Research Agency FERA",
  "Governance and Assurance",
  "Graduate Students Association",
  "Health, Econometrics & Data Group",
  "Health Professions Education Unit",
  "Health Sciences",
  "Health Sciences apprenticeships",
  "Health Sciences commissioned",
  "Health Sciences NHS Contract",
  "Health Sciences online",
  "Health Sciences Teaching",
  "Health Services and Policy Group",
  "Heslington Studios Ltd",
  "Higher Ed Partners - UK Ltd",
  "Higher Education Academy",
  "History",
  "History of Art",
  "HR Leadership Team",
  "HR Operations and Services",
  "HR Specialists and Systems",
  "HS Support Services and Dept Admin",
  "Hull HYMS",
  "Hull York Medical School",
  "Humanities Research Centre",
  "Human Resources",
  "HYMS Asymmetric Costs",
  "HYMS Dean's Office",
  "HYMS/Hull asymmetric",
  "HYMS/Hull symmetric",
  "HYMS-Mental Health Research Group",
  "HYMS-Public Health Research Group",
  "HYMS Research Consortium",
  "IFAB",
  "Information Services",
  "Inst. for Research in Social Science",
  "Institute for Effective Education",
  "Institute for Safe Autonomy",
  "Inst - Public Understanding of the Past",
  "Int Centre Study Urban Vulnerability",
  "International Pathway College",
  "Intl Recruitment,Partnerships & Mobility",
  "IPC LLP Kaplan Activity",
  "IPC LLP York Activity",
  "IT Incubator Ltd",
  "IT Services",
  "Jack Birch Unit",
  "Knowledge Exchange Enhancement Team",
  "Knowledge Transfer Partnership",
  "Language and Linguistic Science",
  "Languages for All",
  "Language Teaching Centre",
  "Legal and Health and Safety",
  "Library, Archives and Learning Services",
  "Low temp plasma ST, York-Paris CIRC",
  "Macromolecules",
  "Management Accounts",
  "MATEYL",
  "Mathematical Finance online",
  "Mathematics",
  "Medical Centre",
  "Medical Education Unit",
  "MEG Electronics Consortium",
  "Mental Health Research Group",
  "Mental Health Social Research IC",
  "Microbiology",
  "Miscellaneous",
  "Mktg,Recruitment,Admissions & Outreach",
  "Morandal Ltd",
  "Morrell Centre for Toleration",
  "Music",
  "N8 AgriFood Programme",
  "National Science Learning Centre",
  "Natural Sciences",
  "NorMAN (out of hours IT)",
  "Norwegian Study Centre",
  "Not in use",
  "NYCH Continuing Education",
  "NYCH e-University",
  "NYCH Research, Conferences and Other",
  "Online Courses (Economics)",
  "Online Courses (SPSW)",
  "Online Courses (TYMS)",
  "Online Fees & Costs",
  "Online Partnerships",
  "Open Learning Centres",
  "Operations Management",
  "p53 Group",
  "PCMIS Health Technologies Ltd",
  "PCMIS Health Technologies (UoY)",
  "People and Organisational Development",
  "Perryquest Ltd",
  "Philanthropic Partnerships and Alumni",
  "Philosophy",
  "Physics",
  "Physics Electronics Store",
  "Planning and Risk",
  "Plant Journal",
  "Plasma Institute",
  "Politics",
  "Politics, Economics and Philosophy",
  "Postgraduate Studies",
  "Post-war Reconstruction and Development",
  "Procurement and Transactional Services",
  "Psychology",
  "Public Health and Society",
  "Registrar and Secretary's office",
  "Research",
  "Research Centre for Social Sciences",
  "Research Development",
  "Research Dev Fund & Studentships",
  "Research Grants Office",
  "Research, Innovation & KnowledgeExchange",
  "Research Innovation Office",
  "Research & Knowledge Exchange Contracts",
  "Research Strategy and Policy",
  "Reviews",
  "School of Social and Political Sciences",
  "Science City York",
  "SkillsForge Ltd",
  "Skin Research Centre",
  "Social Policy",
  "Social Policy and Social Work",
  "Social Policy Research Unit",
  "Social Work",
  "Social Work Research & Development Unit",
  "Sociology",
  "Springboard",
  "Stained Glass Conservation",
  "Stockholm Environment Institute at York",
  "Stodham Investments Limited",
  "Strategic Insight and Analysis Team",
  "Student Accommodation Provision 2 LLP",
  "Student Accommodation Provision 3 LLP",
  "Student Accommodation Provision LLP",
  "Student Admin and Academic Affairs",
  "Student and Academic Services",
  "Student Careers and Systems",
  "Student Life and Wellbeing",
  "Students Union",
  "Technology, Estates and Facilities",
  "Temp Pool",
  "Theatre, Film, TV and Interactive Media",
  "The York Law School",
  "The York Management School",
  "The York Policy Engine",
  "Tutor in Art",
  "UNISON union",
  "University and College Union",
  "University Support Services",
  "UoY International Pathway College LLP",
  "UoY Library Company Ltd",
  "VAT and Subsidiary Companies Office",
  "Vision4Vision CIRC",
  "White Hart",
  "White Rose College of Arts & Humanities",
  "White Rose Consortium",
  "White Rose Grid",
  "Xceleron Ltd",
  "YHPHO (associates Health Science)",
  "YIMS Programme manager",
  "YNI Ltd",
  "York BioMedical Research Institute",
  "York College",
  "York Commercial Ltd",
  "York Cross-disc Ctr for Systems Analysis",
  "York Electronics Centre",
  "York EMC Services Ltd",
  "York Environmental Sustainability Inst",
  "York Health Economics Consortium",
  "York Health Policy Group",
  "York-JEOL Centre Nanofabrication & Analy",
  "York - Maastricht",
  "York Neuroimaging Centre",
  "York Sports Village LLP",
  "York Trials Unit and Statistics",
  "York University Energy Supply Co Ltd",
  "York University Property Co Ltd",
  "YUSU Commercial Services",
]
