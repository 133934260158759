import React from 'react'
import AddArchiveOwners from './addarchiveowners'
import { Auth } from 'aws-amplify'
import VaultExpirySelector from './vaultexpiryselector'
import Tooltip from './tooltip'
import { navigate } from 'gatsby'
import { authenticatedLoginContext } from './authenticatedLoginContext'
import { departments } from './departments'

// Web form for creating a new vault.
class AddArchiveForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAuthenticatedUser: [],
      title: '',
      description: '',
      doi: '',
      department: 0,
      expiryDate: '',
      owners: [],
      uploadedNewArchive: false,
      apiResponseStatus: 0,
      alertClassString: '',
      makingPostRequest: false,
      buttonText: 'Create new vault'
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.addNewArchive = this.addNewArchive.bind(this);
    this.updateAlertClass = this.updateAlertClass.bind(this);
    this.ownersCallback = this.ownersCallback.bind(this);
    this.expiryDateCallback = this.expiryDateCallback.bind(this);
  }

  componentDidMount() {
    // Despite looking strange, it's necessary for this to be a list as
    // AddArchiveOwners assigns the currentAuthenticatedUser value to its
    // 'owners' state, whose value must be a list (it uses `map` on it).
    this.setState({
      currentAuthenticatedUser: [this.context.username]
    });
  }

  // Callback function that is sent to AddArchiveOwner components via props.
  // This allows any AddArchiveOwner components to call this function, updating
  // the state of this component. Allows children to send data to parents.
  ownersCallback(owners) {
    this.setState({
      owners: owners
    });
  }

  expiryDateCallback(expiryDate) {
    this.setState({
      expiryDate: expiryDate
    });
  }

  async addNewArchive() {
    await this.setState({
      makingPostRequest: true,
      buttonText: 'Loading...'
    });

    const { title, description, expiryDate, doi, owners } = this.state;

    let newArchive = {
      title: title,
      description: description,
      expires: expiryDate,
      doi: doi,
      owners: owners,
      department: departments[this.state.department]
    };

    let newArchiveString = JSON.stringify(newArchive);

    Auth.currentSession()
      .then(async data => {
        const res = await fetch(`${process.env.GATSBY_API_URL}/vaults`,
          { method: 'POST',
            mode: 'cors',
            headers: new Headers({
              'Authorization': 'Bearer ' + data.idToken.jwtToken
            }),
            body: newArchiveString });

        let message = await res.json();

        this.setState({
          uploadedNewArchive: true,
          apiResponseStatus: res.status,
          apiResponseMessage: message.message
        });

        this.updateAlertClass();
      })
      .catch(err => console.log(err));
  }

  updateAlertClass() {
    const { apiResponseStatus } = this.state;

    if (apiResponseStatus === 200) {
      var alertClassString = 'c-alert c-alert--success';
      navigate('/');
    } else if (apiResponseStatus === 409) {
      alertClassString = 'c-alert c-alert--warning c-alert'
    } else {
      alertClassString = 'c-alert c-alert--danger c-alert'
    }

    this.setState({
      alertClassString: alertClassString,
      makingPostRequest: false,
      buttonText: 'Create new vault'
    });
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    return(
      <React.Fragment>
          { this.state.uploadedNewArchive &&
            <div class={this.state.alertClassString} role='alert'>
              <div class='c-alert__content'>
                <strong>{this.state.apiResponseMessage}</strong>
              </div>
            </div>
          }
          <h3>Create new vault</h3>
          <br />
        <div class='c-form__element'>
          <label class='c-form__label' for='a'>
            Title <Tooltip>This CANNOT be changed later.</Tooltip>
          </label>
          <input
            class='c-form__input c-form__input--text'
            type='text'
            name='title'
            value={this.state.title}
            onChange={this.handleInputChange}
            placeholder='A descriptive title for your vault' />
        </div>

        <br />

        <div class='c-form__element'>
          <label class='c-form__label'>
            Description
          </label>
          <input
            class='c-form__input c-form__input--text'
            type='text'
            name='description'
            value={this.state.description}
            onChange={this.handleInputChange}
            placeholder='A short description about what data this vault contains'
          />
        </div>

        <br />

        <div class='c-form__element'>
          <label class='c-form__label'>
            DOI <Tooltip>A Digital Object Identifier which uniquely identifies the research data being stored in this vault.</Tooltip>
          </label>
          <input
            class='c-form__input c-form__input--text'
            type='text'
            name='doi'
            value={this.state.doi}
            onChange={this.handleInputChange}
            placeholder='A unique identifier only required for vaults storing research data' />
        </div>

        <br />

        <div class='c-form__element c-form__element--select'>
          <label class='c-form__label'>Select which department this vault is for</label>
          <div class='c-form__input c-form__input--select'>
            <select
              class='c-form__select'
              name='department'
              value={this.state.department}
              onChange={this.handleInputChange}
            >
              {
                departments.sort().map((department, index) => (
                  // Note the use of "index" for the value, not "department".
                  <option value={index}>{department}</option>
                ))              
              }
            </select>
          </div>
        </div>

        <br />

        <label class='c-form__label'>
          Owners
        </label>
        <AddArchiveOwners callback={this.ownersCallback} initialArchiveOwners={[]} />
	<p>Select at least three users who will be responsible for this archive.</p>
	<br />

        <label class='c-form__label'>
          Select an expiry date <Tooltip>This must be at least thirty days in the future.</Tooltip>
        </label>

        <br />
        <VaultExpirySelector callback={this.expiryDateCallback} />
        <p />
        <p>This vault and <b>all its contents</b> will be permanently deleted on this date at 12:00 UTC.</p>
        <div class='o-grid__box o-grid__box--full'>
        <button
          class='c-btn c-btn--success c-btn--medium'
          onClick={this.addNewArchive}
        >
        {this.state.buttonText}
        </button>
        </div>
      </React.Fragment>);
  }
}

AddArchiveForm.contextType = authenticatedLoginContext;

export default AddArchiveForm
